
<template>
  <transition-group>
    <div class="menuCon" v-if="indexObj.index === 0 && showMenu">
      <div class="header">
        <div class="title">
          <span class="titleItem">{{
            state.menuList[indexObj.index].name.toUpperCase()
          }}</span>

          <div class="sjx" v-if="indexObj.Level1Index !== null"></div>
          <span v-if="indexObj.Level1Index !== null" class="titleItem">{{
            state.menuList[indexObj.index].child[
              indexObj.Level1Index
            ].name.toUpperCase()
          }}</span>
        </div>
        <div class="back" v-show="showMenuL1" @click="clickL1"></div>
      </div>
      <div class="Level1">
        <div class="con padding4a" v-if="!showMenuL1">
          <div
            class="boxBtn"
            v-for="(item, index) in state.menuList[indexObj.index].child"
            :key="index"
            @click="menuToScene(item.path.toUpperCase())"
          >
            <div class="menuItem16">{{ item.name.toUpperCase() }}</div>
          </div>
        </div>
        <!-- <div class="con padding1b"  v-else>
          <div class="boxBtn width220" v-for="(item, index) in activeList" :key="index" >
            <div class="menuItem16" @click="toScene(item.path.toUpperCase())"> {{ item.name.toUpperCase() }}</div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="menuCon" v-else-if="indexObj.index === 1 && showMenu">
      <div class="header">
        <div class="title">
          <span class="titleItem">{{
            state.menuList[indexObj.index].name.toUpperCase()
          }}</span>
          <div class="sjx" v-if="indexObj.Level1Index !== null"></div>
          <span v-if="indexObj.Level1Index !== null" class="titleItem">{{
            state.menuList[indexObj.index].child[
              indexObj.Level1Index
            ].name.toUpperCase()
          }}</span>
        </div>
        <div class="back" v-show="showMenuL1" @click="clickL1"></div>
      </div>
      <div class="Level1">
        <div class="con padding2a" v-if="!showMenuL1">
          <div
            class="boxBtn"
            v-for="(item, index) in state.menuList[indexObj.index].child"
            :key="index"
            @click="switchMenuL1(index)"
          >
            <div class="menuItem16">{{ item.name.toUpperCase() }}</div>
          </div>
        </div>
        <div class="con padding2b" v-else>
          <div class="conbox B10L1con" v-if="indexObj.Level1Index === 0">
            <!-- <div class="boxBtn pf3  " :class="{ width30: index % 3 === 0, width250: index % 3 === 1, width146: index % 3 === 2 }"   v-for="(item, index) in activeList" :key="index"> -->
            <div
              class="boxBtn pf3"
              :class="{
                left: index % 3 === 0,
                center: index % 3 === 1,
                right: index % 3 === 2,
              }"
              v-for="(item, index) in activeList"
              :key="index"
            >
              <div class="menuItem16" @click="toScene(item.path.toUpperCase())">
                {{ item.name.toUpperCase() }}
              </div>
            </div>
          </div>
          <div class="conbox B10L5con" v-if="indexObj.Level1Index === 1">
            <div
              class="boxBtn pf3"
              :class="{
                left: index % 3 === 0,
                center: index % 3 === 1,
                right: index % 3 === 2,
              }"
              v-for="(item, index) in activeList"
              :key="index"
            >
              <div class="menuItem16" @click="toScene(item.path.toUpperCase())">
                {{ item.name.toUpperCase() }}
              </div>
            </div>
          </div>
          <div class="conbox B10L13con" v-if="indexObj.Level1Index === 2">
            <div
              class="boxBtn pf3"
              :class="{
                left: index % 3 === 0,
                center: index % 3 === 1,
                right: index % 3 === 2,
              }"
              v-for="(item, index) in activeList"
              :key="index"
            >
              <div class="menuItem16" @click="toScene(item.path.toUpperCase())">
                {{ item.name.toUpperCase() }}
              </div>
            </div>
          </div>
          <div class="conbox B10L24con" v-if="indexObj.Level1Index === 3">
            <div
              class="boxBtn pf3"
              :class="{
                left: index % 3 === 0,
                center: index % 3 === 1,
                right: index % 3 === 2,
              }"
              v-for="(item, index) in activeList"
              :key="index"
            >
              <div class="menuItem16" @click="toScene(item.path.toUpperCase())">
                {{ item.name.toUpperCase() }}
              </div>
            </div>
          </div>

          <!-- :class="{ width30: index % 3 === 0, width250: index % 3 === 1, width146: index % 3 === 2 }"  -->
          <!-- <div class="conbox" v-for="(item, index) in activeList" :key="index"> -->
          <!-- <div class="boxBtn pf3  " :class="{ width30: index % 3 === 0, width250: index % 3 === 1, width146: index % 3 === 2 }"   v-for="(item, index) in activeList" :key="index">
                <div class="menuItem16"  @click="toScene(item.path.toUpperCase())"> {{ item.name.toUpperCase() }}</div>
              </div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="menuCon" v-else-if="indexObj.index === 2 && showMenu">
      <div class="header">
        <div class="title">
          <span class="titleItem">{{
            state.menuList[indexObj.index].name.toUpperCase()
          }}</span>
          <div class="sjx" v-if="indexObj.Level1Index !== null"></div>
          <span v-if="indexObj.Level1Index !== null" class="titleItem">{{
            state.menuList[indexObj.index].child[
              indexObj.Level1Index
            ].name.toUpperCase()
          }}</span>
        </div>
        <div class="back" v-show="showMenuL1" @click="clickL1"></div>
      </div>
      <div class="Level1">
        <div class="con padding3a" v-if="!showMenuL1">
          <div
            class="boxBtn"
            v-for="(item, index) in state.menuList[indexObj.index].child"
            :key="index"
            @click="switchMenuL1(index)"
          >
            <div class="menuItem16">{{ item.name.toUpperCase() }}</div>
          </div>
        </div>

        <!-- indexObj.Level1Index -->
        <div class="con padding3b" v-else>
          <div class="conbox B9L1con" v-if="indexObj.Level1Index === 0">
            <div
              class="boxBtn pf3"
              :class="{
                left: index % 3 === 0,
                center: index % 3 === 1,
                right: index % 3 === 2,
              }"
              v-for="(item, index) in activeList"
              :key="index"
            >
              <div class="menuItem16" @click="toScene(item.path.toUpperCase())">
                {{ item.name.toUpperCase() }}
              </div>
            </div>
          </div>
          <div class="conbox B9L4con" v-if="indexObj.Level1Index === 1">
            <div
              class="boxBtn pf3"
              :class="{
                left: index % 3 === 0,
                center: index % 3 === 1,
                right: index % 3 === 2,
              }"
              v-for="(item, index) in activeList"
              :key="index"
            >
              <div class="menuItem16" @click="toScene(item.path.toUpperCase())">
                {{ item.name.toUpperCase() }}
              </div>
            </div>
          </div>
          <div class="conbox B9L5con" v-if="indexObj.Level1Index === 2">
            <div
              class="boxBtn pf3"
              :class="{
                left: index % 3 === 0,
                center: index % 3 === 1,
                right: index % 3 === 2,
              }"
              v-for="(item, index) in activeList"
              :key="index"
            >
              <div class="menuItem16" @click="toScene(item.path.toUpperCase())">
                {{ item.name.toUpperCase() }}
              </div>
            </div>
          </div>
          <div class="conbox B9L13con" v-if="indexObj.Level1Index === 3">
            <div
              class="boxBtn pf3"
              :class="{
                left: index % 3 === 0,
                center: index % 3 === 1,
                right: index % 3 === 2,
              }"
              v-for="(item, index) in activeList"
              :key="index"
            >
              <div class="menuItem16" @click="toScene(item.path.toUpperCase())">
                {{ item.name.toUpperCase() }}
              </div>
            </div>
          </div>

          <!-- <div class="conbox" v-if="indexObj.Level1Index === 0"> -->
          <!-- <div class="boxBtn pf3"  v-for="(item, index) in activeList" :key="index" :class="{ width30: index % 3 === 0, width240: index % 3 === 1, width200: index % 3 === 2 }">
              <div class="menuItem16" @click="toScene(item.path.toUpperCase())"> {{ item.name.toUpperCase() }}</div>
            </div> -->
          <!-- </div> -->

          <!-- <div class="boxBtn pf3"  v-for="(item, index) in activeList" :key="index" :class="{ width30: index % 3 === 0, width240: index % 3 === 1, width200: index % 3 === 2 }">
            <div class="menuItem16" @click="toScene(item.path.toUpperCase())"> {{ item.name.toUpperCase() }}</div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="menuCon" v-else-if="indexObj.index === 3 && showMenu">
      <div class="header">
        <div class="title">
          <span class="titleItem">{{
            state.menuList[indexObj.index].name.toUpperCase()
          }}</span>
          <div class="sjx" v-if="indexObj.Level1Index !== null"></div>
          <span v-if="indexObj.Level1Index !== null" class="titleItem">{{
            state.menuList[indexObj.index].child[
              indexObj.Level1Index
            ].name.toUpperCase()
          }}</span>
        </div>
        <div class="back" v-show="showMenuL1" @click="clickL1"></div>
      </div>
      <div class="Level1">
        <!-- 二级菜单 -->
        <div class="con padding4a" v-if="!showMenuL1">
          <div
            class="boxBtn"
            v-for="(item, index) in state.menuList[indexObj.index].child"
            :key="index"
            @click="switchMenuL1(index)"
          >
            <div class="menuItem16">{{ item.name.toUpperCase() }}</div>
          </div>
        </div>

        <div class="con padding4b" v-else>
          <div class="conbox unit1" v-if="indexObj.Level1Index === 0">
            <div
              class="boxBtn pf3"
              :class="{
                left: index % 4 === 0,
                center: index % 4 === 1,
                right: index % 4 === 2,
                rightAfter: index % 4 === 3,
              }"
              v-for="(item, index) in activeList"
              :key="index"
            >
              <div class="menuItem16" @click="showPic(item)">
                {{ item.name.toUpperCase() }}
              </div>
            </div>
          </div>
          <div class="conbox unit2" v-if="indexObj.Level1Index === 1">
            <div
              class="boxBtn pf3"
              :class="{
                left: index % 4 === 0,
                center: index % 4 === 1,
                right: index % 4 === 2,
                rightAfter: index % 4 === 3,
              }"
              v-for="(item, index) in activeList"
              :key="index"
            >
              <div class="menuItem16" @click="showPic(item)">
                {{ item.name.toUpperCase() }}
              </div>
            </div>
          </div>
          <!-- <div
            class="boxBtn"
            style="width: 25%"
            v-for="(item, index) in activeList"
            :key="index"
          >
            <div class="menuItem16" @click="showPic(item)">
              {{ item.name.toUpperCase() }}
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </transition-group>

  <div class="menu">
    <div class="home" @click="reset"></div>
    <div
      :class="[
        'menuItem',
        indexObj.index === index && indexObj.isopen ? 'active' : '',
      ]"
      v-for="(item, index) in state.menuList"
      :key="index"
      @click="switchMenu(index)"
    >
      {{ item.name.toUpperCase() }}
    </div>
  </div>
</template>
  
<script setup >
import { ref, onMounted, reactive, defineProps, inject } from "vue";
import { menuData } from "./data.js";
const props = defineProps({
  showBg: {
    type: Function,
    required: true,
  },
  mbjtClose: {
    type: Function,
    required: true,
  },
});
const toggleSwiper = inject("toggleSwiper");
const panoShow = inject("panoShow");
const panoHide = inject("panoHide");
const bgImageShow = inject("bgImageShow");
const bgImageHide = inject("bgImageHide");

const showPic = (item) => {
  console.log(item);
  toggleSwiper(item.path);
};
let showMenu = ref(false);
const reset = () => {
  panoShow();
  showMenu.value = false;
  indexObj.first = true;
  indexObj.isopen = false;
  indexObj.index = null;
  indexObj.Level1Index = null;
  indexObj.Level2Index = null;
  props.mbjtClose();
};

const menuToScene = (scene)=>{
  toScene(scene);
  setTimeout(() => {
    bgImageHide();
    
  }, 300);
}

const toScene = (scene) => {
  console.log(scene);

  panoHide();
  Window.toScene(scene);
};
const indexObj = reactive({
  first: true,
  isopen: false,
  index: null,
  Level1Index: null,
  Level2Index: null,
});
let timer = null;
const open = () => {
  showMenu.value = true;
  indexObj.index = page.value;
  indexObj.isopen = true;
};

const close = () => {
  showMenu.value = false;
  indexObj.isopen = false;
  // props.mbjtClose();
};
defineExpose({
  close,
  open,
});

const page = ref(0);
const switchMenu = (index) => {
  page.value = index;
  clickL1();
  clearTimeout(timer);
  showMenuL1.value = false;
  if (indexObj.first) {
    open(index);
    indexObj.first = false;
    props.showBg();
  } else {
    if (indexObj.index === index) {
      if (indexObj.isopen) {
        close();
      } else {
        open(index);
        props.showBg();
      }
    } else {
      if (indexObj.isopen) {
        close();
        timer = setTimeout(() => {
          open(index);
        }, 700);
      } else {
        open(index);
        props.showBg();
      }
    }
  }
  // }
};
let showMenuL1 = ref(false);
let activeList = reactive([]);
const switchMenuL1 = (index) => {
  console.log("switchMenuL1===", index);

  // if (index === 1) {
  //   showMenuL1.value = false;
  //   indexObj.Level1Index = null;
  //   return;
  // }else if(index === 2){

  // }
  indexObj.Level1Index = index;
  activeList = state.menuList[indexObj.index].child[index].child;
  console.log("activeList===", activeList);


  console.log("indexObj.index===", indexObj.index);
  console.log("index===", index);
  if (indexObj.index == 3) {

    bgImageShow();
  }else{
    bgImageHide();
  }
  if (indexObj.index === 1) {
    // b10
      if (index===0) {
        toScene(activeList[6].path);
      }else if (index===2){
        toScene(activeList[5].path);

      }else {
        toScene(activeList[0].path);

      }
  }else  if(indexObj.index == 2){
    // b9
    if (index===0) {
            toScene(activeList[3].path);
          }else if (index===2){
            toScene(activeList[0].path);

          }else {
            toScene(activeList[0].path);

          }
  } else  if(indexObj.index == 3){
    // if (index===0) {
      // hxshow()
    // }
  }
  showMenuL1.value = true;
};

const clickL1 = () => {
  indexObj.Level1Index = null;
  showMenuL1.value = false;
};
const state = {
  menuList: menuData,
};
</script>
  
<style scoped lang="scss"">
$borderradius: 6px;

@media screen and (min-width: 1601px) {
  .B10L1con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .left {
      width: 200px;
    }
    .center {
      width: 196px;
      margin: 0 30px;
    }
    .right {
      width: 210px;
    }
  }

  .B10L5con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .left {
      width: 165px;
    }
    .center {
      width: 150px;
      margin: 0 120px;
    }
    .right {
      width: 146px;
    }
  }

  .B10L13con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .left {
      width: 200px;
    }
    .center {
      width: 236px;
      margin: 0 50px;
    }
    .right {
      width: 130px;
    }
  }
  .B10L24con {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .B9L1con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .left {
      width: 200px;
    }
    .center {
      width: 210px;
      margin: 0 60px;
    }
    .right {
      width: 170px;
    }
  }
  .B9L4con {
    display: flex;
    justify-content: space-between;
    padding: 0 200px;
  }
  .B9L5con {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      width: 210px;
    }
    .center {
      width: 190px;
      margin: 0 60px;
    }
    .right {
      width: 150px;
    }
  }

  .B9L13con {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      width: 210px;
    }
    .center {
      width: 230px;
      margin: 0 60px;
    }
    .right {
      width: 160px;
    }
  }
  .unit1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      width: 90px;
    }
    .center {
      width: 116px;
      margin: 0 100px;
    }
    .right {
      width: 90px;
      margin: 0 100px;
    }
    .rightAfter {
      width: 106px;
    }
  }
  .unit2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      width: 116px;
    }
    .center {
      width: 116px;
      margin: 0 90px;
    }
    .right {
      width: 104px;
      margin: 0 90px;
    }
    .rightAfter {
      width: 106px;
    }
  }

  .width30 {
    width: 30% !important;
  }
  .width230 {
    width: 230px !important;
  }
  .width146 {
    width: 233px !important;
  }
  .width240 {
    width: 252px !important;
  }
  .width250 {
    width: 250px !important;
  }
  .width200 {
    width: 200px !important;
  }

  .padding1a {
    padding: 0 100px;
  }
  .padding1b {
    padding: 0 150px;
  }
  .padding2a {
    padding: 0 100px;
  }
  .padding2b {
    padding: 0 80px;
  }
  .padding3a {
    padding: 0 100px;
  }
  .padding3b {
    padding: 0 80px;
    // justify-content: flex-start !important;
  }
  .padding4a {
    padding: 0 260px;
  }
  .padding4b {
    padding: 0 70px;
    // justify-content: flex-start !important;
    // .boxBtn{
    //   padding-left: 50px;
    // }
  }

  .titleItem {
    color: rgba(255, 255, 255, 0.5);
    font-size: 11px;
    line-height: 24px;
  }
  .menuItem {
    width: fit-content;
    height: 24px;
    line-height: 24px;
    border-radius: $borderradius;
    margin: 6px;
    padding: 0 6px;
    color: white;
    font-size: 20px;
    word-break: break-all;
    border-radius: 6px;
    cursor: pointer;
  }

  .menuItem16 {
    width: fit-content;
    height: 24px;
    line-height: 24px;
    border-radius: $borderradius;
    margin: 6px;
    padding: 0 6px;
    color: white;
    font-size: 16px;
    word-break: break-all;
    border-radius: $borderradius;
    cursor: pointer;
  }
  .menuCon {
    min-width: 1000px;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 60px;
    background-color: rgba(0, 0, 0, 0.5);
    height: fit-content;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: $borderradius;
    .Level1 {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0px;

      .menuItem {
        margin: 0;
        padding: 0;
      }

      .box {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .sjx {
    background: url(/sjx.png) no-repeat center / contain;
    width: 12px;
    height: 12px;
    margin: 0 20px;
    display: inline-block;
    position: relative;
    top: 2px;
  }

  .home {
    width: 30px;
    height: 30px;
    color: #fff;
    text-align: center;
    background: url(/home.png) no-repeat center / contain;
    margin: 0 10px;
    cursor: pointer;
  }

  .menu {
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: $borderradius;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // min-width: 1000px;
    height: 100%;
    padding: 0 16px;
  }

  .header {
    border-bottom: 1px solid #ffffff;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0 5px;
    padding-left: 80px;
    padding-right: 6px;
  }
  .active {
    background: rgb(189, 118, 95);
  }

  .v-enter-from {
    opacity: 0;
    transform: translate(-50%, 100%);
  }

  .v-enter-active {
    transition: all 0.75s ease;
    position: absolute;
  }

  .v-enter-to {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  .v-leave-from {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  .v-leave-active {
    transition: all 0.75s ease;
  }

  .v-leave-to {
    opacity: 0;
    transform: translate(-50%, -50%);
  }

  .back {
    // position: absolute;
    // right: 2px;
    // top: 2px;
    width: 26px;
    height: 26px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    background: url("../assets/back.png") no-repeat center/cover;
  }

  .con {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .boxBtn {
    width: fit-content;
    min-width: fit-content;
    height: 100%;
    display: flex;
    justify-content: start;
    font-size: 16px;
    text-align: center;
    gap: 20px;
  }

  .pf3 {
    // width: 33.333% !important;
    // flex-basis:calc(33.33% - 20px)
  }
  .boxBtn :hover {
    background: rgb(189, 118, 95);
  }
  .width220 {
    width: 220px !important;
  }
}
@media screen and (max-width: 1600px) {
  .B10L1con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .left {
      width: 116px;
    }
    .center {
      width: 114px;
      margin: 0 30px;
    }
    .right {
      width: 124px;
    }
  }

  .B10L5con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .left {
      width: 96px;
    }
    .center {
      width: 88px;
      margin: 0 80px;
    }
    .right {
      width: 86px;
    }
  }

  .B10L13con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .left {
      width: 118px;
    }
    .center {
      width: 138px;
      margin: 0 50px;
    }
    .right {
      width: 68px;
    }
  }
  .B10L24con {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .B9L1con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .left {
      width: 118px;
    }
    .center {
      width: 126px;
      margin: 0 60px;
    }
    .right {
      width: 100px;
    }
  }
  .B9L4con {
    display: flex;
    justify-content: space-between;
    padding: 0 90px;
  }
  .B9L5con {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      width: 128px;
    }
    .center {
      width: 118px;
      margin: 0 30px;
    }
    .right {
      width: 88px;
    }
  }

  .B9L13con {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      width: 96px;
    }
    .center {
      width: 136px;
      margin: 0 60px;
    }
    .right {
      width: 94px;
    }
  }
  .unit1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      width: 46px;
    }
    .center {
      width: 64px;
      margin:  0 60px;
    }
    .right {
      width: 46px;
      margin:  0 60px;
    }
    .rightAfter {
      width: 56px;
    }
  }
  .unit2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      width: 64px;
    }
    .center {
      width: 64px;
      margin:  0 60px;
    }
    .right {
      width: 64px;
      margin:  0 60px;
    }
    .rightAfter {
      width: 50px;
    }
  }

  .width30 {
    width: 155px !important;
  }
  .width230 {
    width: 110px !important;
    min-width: 125px !important;
  }
  .width220 {
    width: 170px !important;
    display: flex;
    justify-content: center !important;
    // min-width: 125px !important;
  }
  .width146 {
    width: 140px !important;
    min-width: 80px !important;
  }
  .width250 {
    width: 150px !important;
  }
  // .width4{
  //   width:80px !important;
  // }
  .width240 {
    width: 150px !important;
  }
  .width200 {
    width: 120px !important;
  }

  .padding1a {
    padding: 0 64px;
  }
  .padding1b {
    padding: 0 80px;
  }
  .padding2a {
    padding: 0 50px;
  }
  .padding2b {
    padding: 0 50px;
    // justify-content: flex-start !important;
  }
  .padding3a {
    padding: 0 50px;
  }
  .padding3b {
    padding: 0 40px;
    // justify-content: flex-start !important;
  }
  .padding4a {
    padding: 0 160px;
  }
  .padding4b {
    padding: 0 50px;
    // .boxBtn{
    //   padding-left: 40px;
    // }
  }

  .titleItem {
    color: rgba(255, 255, 255, 0.5);
    font-size: 8px;
    line-height: 24px;
  }

  .menuItem16 {
    width: fit-content;
    height: 24px;
    line-height: 24px;
    border-radius: $borderradius;
    color: white;
    font-size: 10px;
    word-break: break-all;
    border-radius: $borderradius;
    cursor: pointer;
    padding: 0 3px;
  }
  .menuItem {
    width: fit-content;
    height: 24px;
    line-height: 24px;
    border-radius: $borderradius;
    margin: 6px;
    padding: 0 6px;
    color: white;
    font-size: 12px;
    word-break: break-all;
    border-radius: $borderradius;
    cursor: pointer;
  }
  .menuCon {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    height: fit-content;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: $borderradius;

    .Level1 {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0px;

      .menuItem {
        margin: 0;
        padding: 0;
      }

      .box {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .sjx {
    background: url(/sjx.png) no-repeat center / contain;
    width: 10px;
    height: 10px;
    margin: 0 12px;
    display: inline-block;
    position: relative;
    top: 2px;
  }

  .home {
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    background: url(/home.png) no-repeat center / contain;
    margin: 0 10px;
    cursor: pointer;
  }

  .menu {
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: $borderradius;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 16px;
  }

  .header {
    border-bottom: 1px solid #ffffff;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0 5px;
    padding-left: 50px;
    padding-right: 6px;
  }
  .active {
    background: rgb(189, 118, 95);
  }

  .v-enter-from {
    opacity: 0;
    transform: translate(-50%, 100%);
  }

  .v-enter-active {
    transition: all 0.75s ease;
    position: absolute;
  }

  .v-enter-to {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  .v-leave-from {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  .v-leave-active {
    transition: all 0.75s ease;
  }

  .v-leave-to {
    opacity: 0;
    transform: translate(-50%, -50%);
  }

  .back {
    // position: absolute;
    // right: 2px;
    // top: 2px;
    width: 22px;
    height: 22px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    background: url("../assets/back.png") no-repeat center/cover;
  }

  .con {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .boxBtn {
    width: fit-content;
    min-width: 0;
    height: 100%;
    display: flex;
    justify-content: start;
    padding: 4px 0px;
    font-size: 16px;
  }
  .boxBtn :hover {
    background: rgb(189, 118, 95);
  }
}
</style>
  
  