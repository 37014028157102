<template>
  <swiper
    :modules="[Virtual, Navigation]"
    :slides-per-view="1"
    :space-between="50"
    virtual
    :navigation="true"
    class="swiperbox"
  >
    <swiper-slide
      v-for="(item, index) in imageList"
      :key="index"
      :virtualIndex="index"
      @click="showImg(item)"
    >

    
      <!-- <div class="box" :style="`background: url(${item.image}) no-repeat center / contain`"> -->
      <div class="box">
        <!-- <img
        :src="item.svg"
        alt=""
        srcset=""
        @click="getOnclick(item)"
        :data-original="`${item}`"
        class="image"
      /> -->
        <!-- <SvgB10L13 /> -->
        <component :is="currentComponent"></component>
        <!-- <SvgB9L4 /> -->
      </div>
    </swiper-slide>
  </swiper>

  <!-- <div ref="imageRef" class="box" v-show="imageshow">
    <img
      :src="image"
      alt=""
      class="image"
      @click="getOnclick"
      :data-original="`${image}`"
    />
  </div> -->

  <div class="close" @click="toggleSwiper"></div>
</template>

<script setup>
import { ref, onMounted, reactive, watch, inject } from "vue";
import { Virtual, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
// import VueDraggableResizable from 'vue-draggable-resizable';
// import 'vue-draggable-resizable/dist/VueDraggableResizable.css';


import SvgB10L13 from "./svg/B10_L13.vue";
import SvgB9L4 from "./svg/B9_L4.vue";
import SvgB9L5 from "./svg/B9_L5.vue";
import SvgB9L6 from "./svg/B9_L6.vue";
import SvgB9L7 from "./svg/B9_L7_12_14_23.vue";
import SvgB9L13 from "./svg/B9_L13.vue";
import SvgB9L24 from "./svg/B9_L24.vue";
import SvgB10L4 from "./svg/B10_L4.vue";
import SvgB10L5 from "./svg/B10_L5.vue";
import SvgB10L6 from "./svg/B10_L6_12_14_23.vue";
import SvgB10L24 from "./svg/B10_L24.vue";

onMounted(() => {
  const menuPath = inject("menuPath");
  console.log(menuPath);
  setCurrentComponent(menuPath);
});
const props = defineProps({
  toggleSwiper: { type: Function, required: true },
});
const imageRef = ref(null);
let imageshow = ref(false);

let image = ref("/floor-plan-level1.png");
const currentComponent = ref(SvgB10L13);

const setCurrentComponent = (path) => {
  console.log(path);
  switch (path.value) {
    case "B9_L4":
      currentComponent.value = SvgB9L4;
      break;
    case "B9_L5":
      currentComponent.value = SvgB9L5;
      break;
    case "B9_L6":
      currentComponent.value = SvgB9L6;
      break;
    case "B9_L7":
      currentComponent.value = SvgB9L7;
      break;
    case "B9_L13":
      currentComponent.value = SvgB9L13;
      break;
    case "B9_L24":
      currentComponent.value = SvgB9L24;
      break;
    case "B10_L4":
      currentComponent.value = SvgB10L4;
      break;
    case "B10_L5":
      currentComponent.value = SvgB10L5;
      break;
    case "B10_L6":
      currentComponent.value = SvgB10L6;
      break;
    case "B10_L13":
      currentComponent.value = SvgB10L13;
      break;
    case "B10_L24":
      currentComponent.value = SvgB10L24;
      break;
    default:
      break;
  }
  // currentComponent.value = component;
};

let imageList = reactive([
  {
    image: "/test.png",
    svg: SvgB10L13,
  },
]);
const showImg = (url) => {
  image.value = url;
  imageshow.value = true;
};

const getOnclick = () => {
  imageshow.value = false;
};
</script>

<style scoped>
.svgcon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 1200px;
  height: 600px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
  width: 40px;
  height: 40px;
  background: url(/static/img/close.png) no-repeat center / contain;
  cursor: pointer;
}
.swiperbox {
  width: 100%;
  height: 100%;
  background: rgba(231, 219, 195, 1);
}
.box {
  /* width: 100%;
  height: 100%;  */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
}

@media screen and (max-width: 1600px) {
  .box {
  /* width: 100%;
  height: 100%;  */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
}
.image {
  width: 400px;
  margin: auto;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.swiper-button-prev {
  background-color: #f0f0f0; 
  color: #333; 
  font-size: 24px; 
  border-radius: 50%; 
  padding: 10px; 
  transition: all 0.3s ease; 
  z-index: 12;
  left: 20%;
}
.swiper-button-next {
  background-color: #f0f0f0; 
  color: #333; 
  font-size: 24px; 
  border-radius: 50%; 
  padding: 10px;
  transition: all 0.3s ease; 
  z-index: 12;
  right: 20%;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #ccc; 
}
</style>