export const menuData = [
    {
      name: "RETAIL AREA",
      path: "B10_1_20",
          child: [
            {
              name: "KINETIC TREE",
              path: "B10_1_20",
            },
            {
              name: "CANOPY SHADE TREE",
              path: "B10_1_19",
            },
          ],
      // child: [
      //   {
      //     name: "Shopping Streets",
      //     path: "/",
      //     child: [
      //       {
      //         name: "ORCHARD HILL I STREET SOUTH",
      //         path: "B10_1_16",
      //       },
      //       {
      //         name: "ORCHARD HILL II STREET SOUTH",
      //         path: "B9_1_16",
      //       },
      //       {
      //         name: "ORCHARD HILL I STREET NORTH",
      //         path: "B10_1_18",
      //       },
      //       {
      //         name: "ORCHARD HILL II STREET NORTH",
      //         path: "B9_1_18",
      //       },
      //     ],
      //   },
      //   {
      //     name: "Central Plaza",
      //     path: "/",
      //     child: [
      //       {
      //         name: "KINETIC TREE",
      //         path: "B10_1_20",
      //       },
      //       {
      //         name: "CANOPY SHADE TREE",
      //         path: "B10_1_19",
      //       },
      //     ],
      //   },
      // ],
    },
    {
      name: "Orchard Hill I",
      path: "/",
      child: [
        {
          name: "Level 1",
          path: "/",
          child: [
                  {
              name: "RESIDENTIAL ENTRANCE",
              path: "B10_1_1",
            },
            {
              name: "RIVER PEBBLES LOUNGE",
              path: "B10_1_13",
            },
            {
              name: "MULTIFUNCTIONAL ROOM",
              path: "B10_1_12",
            },
            {
              name: "CO-WORKING SPACE",
              path: "B10_1_7",
            },
            {
              name: "KIDS' ROOM",
              path: "B10_1_14",
            },
            {
              name: "MEETING ROOM",
              path: "B10_1_9",
            },
            {
              name: "GRAND LOBBY",
              path: "B10_1_5",
            },
          ],
        },
        {
          name: "Level 5",
          path: "/",
          child: [
            {
              name: "50M LAP POOL",
              path: "B10_5_4",
            },
            {
              name: "JACUZZI",
              path: "B10_5_11",
            },
            {
              name: "POOL CABANA",
              path: "B10_5_5",
            },
            {
              name: "SHALLOW POOL",
              path: "B10_5_10",
            },
            {
              name: "KIDS' POOL",
              path: "B10_5_12",
            },
            {
              name: "DANCE STUDIO",
              path: "B10_5_14",
            },
            {
              name: "GYM ROOM",
              path: "B10_5_15",
            },
            {
              name: "BBQ BY THE POOL",
              path: "B10_5_3",
            },
            {
              name: "FLOWER GARDEN",
              path: "B10_5_7",
            },
            {
              name: "COMMUNITY ROOM",
              path: "B10_5_2",
            },
           
            {
              name: "",
              path: "/",
            },
          ],
        },
        {
          name: "Level 13",
          path: "/",
          child: [
            {
              name: "Group Sitting Lounge",
              path: "B10_13_4",
            },
            // {
            //   name: "Reading Nook",
            //   path: "B10_13_5",
            // },
            {
              name: "Swing Bed",
              path: "B10_13_5",
            },
            {
              name: "Co-Working",
              path: "B10_13_3",
            },
            // {
            //   name: "Play Table",
            //   path: "B10_13_13",
            // },
            {
              name: "Billiard Table",
              path: "B10_13_12",
            },
            {
              name: "Observation Counter Bar",
              path: "B10_13_8",
            },
            {
              name: "SKY LIBRARY",
              path: "B10_13_1",
            },
            {
              name: "Sport Hub",
              path: "B10_13_10",
            },
          ],
        },
        {
          name: "Level 24",
          path: "/",
          child: [
            {
              name: "PRISMA SKY LOUNGE",
              path: "B10_24_3",
            },
          ],
        },
      ],
    },
    {
      name: "Orchard Hill II",
      path: "/about",
      child: [
        {
          name: "Level 1",
          path: "/",
          child: [
                   {
              name: "RESIDENTIAL ENTRANCE",
              path: "B9_1_1",
            },
            {
              name: "MULTIFUNCTIONAL ROOM",
              path: "B9_1_11",
              child: [],
            },
            {
              name: "CO-WORKING SPACE",
              path: "B9_1_8",
              child: [],
            },
            {
              name: "GRAND LOBBY",
              path: "B9_1_6",
              child: [],
            },
            {
              name: "SILVER NACRE LOUNGE",
              path: "B9_1_5",
              child: [],
            },
            {
              name: "KIDS' ROOM",
              path: "B9_1_14",
              child: [],
            },
            {
              name: "MEETING ROOM",
              path: "B9_1_12",
              child: [],
            },
          ],
        },
        {
          name: "Level 4",
          path: "/",
          child: [
            {
              name: "YOGA STUDIO",
              path: "B9_4_1",
              child: [],
            },
            {
              name: "GYM (LOWER LEVEL)",
              path: "B9_4_2",
              child: [],
            },
          ],
        },
        {
          name: "Level 5",
          path: "/",
          child: [
            {
              name: "5OM LAP POOL",
              path: "B9_5_9",
              child: [],
            },
            {
              name: "JACUZZI",
              path: "B9_5_2",
              child: [],
            },
            {
              name: "SHALLOW POOL",
              path: "B9_5_7",
              child: [],
            },

            {
              name: "KIDS' POOL",
              path: "B9_5_5",
              child: [],
            },
            {
              name: "POOL CABANA",
              path: "B9_5_4",
              child: [],
            },
            {
              name: "BBQ BY THE POOL",
              path: "B9_5_8",
              child: [],
            },
            {
              name: "GYM ROOM (UPPER LEVEL)",
              path: "B9_5_14",
              child: [],
            },
            {
              name: "GOURMET PARTY HOUSE",
              path: "B9_5_10",
              child: [],
            },
            
            {
              name: "",
              path: "/",
              child: [],
            },
          ],
        },
        {
          name: "Level 13",
          path: "/",
          child: [
            {
              name: "ARTISAN LOUNGE",
              path: "B9_13_3",
              child: [],
            },
            {
              name: "OUTDOOR ARTISAN LOUNGE",
              path: "B9_13_7",
              child: [],
            },
            {
              name: "OUTDOOR CINEMA",
              path: "B9_13_8",
              child: [],
            },
            {
              name: "KIDS' PLAYGROUND",
              path: "B9_13_6",
              child: [],
            },
            {
              name: "OBSERVATION DECK",
              path: "B9_13_5",
              child: [],
            },
            {
              name: "CIGAR ROOM",
              path: "B9_13_1",
              child: [],
            },
            
          ],
        },
      ],
    },
   
    {
      name: "Unit Plans",
      path: "/about",
      child: [
        {
          name: "Orchard Hill I",
          path: "/",
          child: [
            {
              name: "Level 4",
              path: "B9_L4",
            }, {
              name: "Level 5",
              path: "B9_L5",
            }, {
              name: "Level 6",
              path: "B9_L6",
            }, {
              name: "Level 7-12",
              path: "B9_L7",
            } , {
              name: "Level 13",
              path: "B9_L13",
            },{
              name: "Level 14-23",
              path: "B9_L7",
            },{
              name: "Level 24",
              path: "B9_L24",
            }, {
              name: "",
              path: "/",
            }
           
          ]
        },
        {
          name: "Orchard Hill II",
          path: "/",
          child: [
            {
              name: "Level 4",
              path: "B10_L4",
            }, {
              name: "Level 5",
              path: "B10_L5",
            }, {
              name: "Level 6-12",
              path: "B10_L6",
            },{
              name: "Level 13",
              path: "B10_L13",
            } , {
              name: "Level 14-23",
              path: "B10_L6",
            }, {
              name: "Level 24",
              path: "B10_L24",
            }, {
              name: "",
              path: "/",
            }, {
              name: "",
              path: "/",
            }
          ],
        },
      ],
    },
  ]