<script setup lang="ts">
import { provide, ref, watch } from "vue";
import { RouterLink, RouterView } from "vue-router";
import HelloWorld from "./components/HelloWorld.vue";
import Swiper from "./components/Swiper.vue";

const data = [
  {
    name: "菜单1",
    children: [
      {
        name: "类目1",
        children: [{ name: "子类1" }, { name: "子类2" }, { name: "子类3" }],
      },
      {
        name: "类目2",
        children: [{ name: "子类1" }, { name: "子类2" }, { name: "子类3" }],
      },
      {
        name: "类目3",
        children: [{ name: "子类1" }, { name: "子类2" }, { name: "子类3" }],
      },
    ],
  },
  {
    name: "菜单2",
    children: [
      {
        name: "类目1",
        children: [{ name: "子类1" }, { name: "子类2" }, { name: "子类3" }],
      },
      {
        name: "类目2",
        children: [{ name: "子类1" }, { name: "子类2" }, { name: "子类3" }],
      },
      {
        name: "类目3",
        children: [{ name: "子类1" }, { name: "子类2" }, { name: "子类3" }],
      },
    ],
  },
  {
    name: "菜单3",
    children: [
      {
        name: "类目1",
        children: [{ name: "子类1" }, { name: "子类2" }, { name: "子类3" }],
      },
      {
        name: "类目2",
        children: [{ name: "子类1" }, { name: "子类2" }, { name: "子类3" }],
      },
      {
        name: "类目3",
        children: [{ name: "子类1" }, { name: "子类2" }, { name: "子类3" }],
      },
    ],
  },
];

const swiperShow = ref(false);

const menuPath = ref('')
const toggleSwiper = (path: string) => {
  console.log(path);
  menuPath.value = path;
  swiperShow.value = !swiperShow.value;
};
watch(menuPath, (newValue:string) => {
  console.log('接收到的新值:', newValue);
});
const style = ref({
  left: '100px',
  top: '100px',
  transform:'scale(0)',
  opacity:1,
  // width:'0px',
  // height:'0px',
});

const infoObj = ref({
  GFA:"110",
  index:"01",
  name:"3BD_C1",
});
const setPos = (pos: { l: string; t: string;scale:number,opacity:number,width:number,height:number})=>{
  Object.assign(style.value, {
    left: pos.l + 'px',
    top: pos.t + 'px',
    transform: `scale(${pos.scale})`,
    opacity: pos.opacity,
  });
}
const setCard = (info: { GFA: any; index: any; name: any; })=>{
  console.log(info);
  infoObj.value.GFA = info.GFA;
  infoObj.value.index = info.index;
  infoObj.value.name = info.name;
}
provide('setPos', setPos);
provide('toggleSwiper', toggleSwiper);
provide('setCard', setCard);
provide('menuPath', menuPath);

</script>

<template>
  <div class="landscape-container">
    <div class="mbbox" >
      <img src="./assets//qt/Bitmap.png" alt="" srcset="" class="rotate"  />
    </div>
    <div class="swiperBox" v-if="swiperShow">
      <Swiper  :toggleSwiper="toggleSwiper"  :menuPath="menuPath"/>
    </div>
    <!-- <div class="huxing" @click="toggleSwiper">查看户型</div> -->
    <HelloWorld />
    <!-- <vue-three-sixty 
      :amount=36
      imagePath="https://scaleflex.cloudimg.io/width/600/q35/https://scaleflex.ultrafast.io/https://scaleflex.airstore.io/demo/chair-360-36"
      fileName="chair_{index}.jpg?v1"
    /> -->
    <!-- <RouterView /> -->
  </div>
  <Card :style="style" :infoObj="infoObj"  v-if="0"/>
</template>

<style scoped lang="scss">
.huxing{
  width: 80px;
  line-height: 30px;
  position: absolute;
  top: 30%;
  font-size: 14px;
  left: 10px;
  background: rgba($color: #000000, $alpha: 0.7);
  text-align: center;
  z-index: 7;
  color: #fff;
  border-radius: 6px;

}
.swiperBox {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 11;
}
.landscape-container {
  position: absolute;
  overflow: hidden;
  .mbbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(44, 44, 44);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    .rotate {
      width: 300px;
      transform: rotate(-90deg);
    }
  }
}

/* // 竖屏 */
@media screen and (orientation: portrait) {
  .landscape-container {
    width: 100vh;
    height: 100vw;
    top: calc((100vh - 100vw) / 2);
    left: calc((100vw - 100vh) / 2);
    transform: rotate(90deg);
    transform-origin: 50% 50%;
  }
}

/* // 横屏 */
@media screen and (orientation: landscape) {
  .landscape-container {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transform: none;
    transform-origin: 50% 50%;
  }
  .mbbox {
    display: none !important;
  }
}
</style>
