
import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'

import App from './App.vue'
import Axios from './apis/ajax'


import 'vue-360/dist/css/style.css'


import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import mountElementUI from './utils/elementUI'

import Vue360 from 'vue-360';

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'


document.title = import.meta.env.VITE_APP_TITLE


 


const app = createApp(App)
app.use(createPinia())
app.provide('$http', Axios)
app.use(router)
app.use(Vue360);
app.use(Viewer, {
    defaultOptions: {
      title: false,
      toolbar: false
    }
  })
// 全量引入Element UI
mountElementUI(app)
app.mount('#app')
