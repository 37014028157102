<template>
  <div class="box">

  <div class="loading" v-if="false">
    loading
  </div>

    <transition name="mbfade" >
      <div class="mbjt" v-if="!mbshow && !first" @click="mbjtClick">
        <img src="/sjt.png" class="sjt" alt="" srcset="" />
      </div>
    </transition>
      <div
      class="mbcli"
      @click="hideMenu"
      @touchstart="hideMenu"
      v-if="mbclishow "
    ></div>
    <img src="/HLOGO.png" class="logo" alt="" srcset=""  @click="mbshow = !mbshow"/>
    <div
    v-if="!isios"
      @click="toggleFullscreen"
      class="Fullscree"
      :class="{ unfullscreen: isFullScreen }"
    ></div>
    <iframe
      src="./static/pano/index.html"
      frameborder="0"
      width="100%"
      height="100%"
      ref="panoIframe"
      @load="panoIframeLoaded"

      v-show="showPano && loaded"
    ></iframe>
    <!-- @load="iframeLoaded" -->

    <iframe
      src="./static/360/threesixty360/index.html"
      frameborder="0"
      v-show="!showPano "
      width="100%"
      height="100%"
      ref="360Iframe"
      @load="iframeLoaded"
    ></iframe>
    <img src="/14.jpg" alt="" srcset="" class="bgImage" v-show="bgShow"/>
    <transition name="fade">
      <div v-if="menuShow" class="menuBox">
        <Menu ref="menuRef" :showBg="showBg"  :mbjtClose="mbjtClose"/>
      </div>
    </transition>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive, watch,provide,onUnmounted } from "vue";
import Menu from "./menu.vue";
import Card from './svg/card.vue';

let isFullScreen = ref(false);
let mbshow = ref(false);
let first = ref(true);

let mbclishow = ref(false);
let showPano = ref(false);
let isios = ref(true);
let loaded = ref(false);
let panoloaded = ref(false);
let panoloading = ref(false);
let bgShow = ref(false);

const bgImageShow = ()=>{
  bgShow.value = true;

}

const bgImageHide = ()=>{
  bgShow.value = false;

}
provide('bgImageShow',bgImageShow);
provide('bgImageHide',bgImageHide);

function getDeviceType() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
 
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {

    isios.value = true;
    return 'iOS';
  } else if (userAgent.match(/Android/i)) {
    isios.value = false;

    return 'Android';
  } else {
    isios.value = false;

    return 'unknown';
  }
}
getDeviceType();

const panoShow = ()=>{
  showPano.value = false;
}
const panoHide = ()=>{
  showPano.value = true;
}



provide('panoShow',panoShow);
provide('panoHide',panoHide);

const mbjtClick = () => {
  // if(first.value){
  menuRef.value.open();
  mbshow.value = true;
  mbclishow.value = true
  // }
};
const mbjtClose = () => {
  // menuRef.value.close();
  mbclishow.value = false
  mbshow.value = false;
  first.value = true;

};


const toggleFullscreen = () => {
  if (isFullScreen.value) {
    document.exitFullscreen();
  } else {
    document.documentElement.requestFullscreen();
  }
};
watch(
  () => mbshow.value,
  (newVal, oldVal) => {
    if (!newVal ) {
      // menuRef.value.close();
      first.value = false;
    }

    //   console.log('oldVal',oldVal);
    //   console.log('newVal',newVal);
  }
);


const hideMenu = () => {
  mbshow.value = false;
  mbclishow.value = false;
  menuRef.value.close();
};

const menuRef = ref(null);
const showMenu = () => {
  mbshow.value = true;
  mbclishow.value = true;
};
onMounted(() => {
  document.addEventListener("fullscreenchange", () => {
    isFullScreen.value = document.fullscreenElement !== null;
  });
});

// iframe
const iframeLoaded = () => {
  // setTimeout(() => {
  // }, 8000);
  console.log('360panoIframeLoaded');
  loaded.value = true;

};


const panoIframeLoaded = () => {
  // setTimeout(() => {
  //   loaded.value = true;
  // }, 8000);
  panoloaded.value = true;
  console.log('panoIframeLoaded');
  
};
const panoIframe = ref(null);


let sceneName = ref('');

let timer = ref(null);

const callIframe = (name)=>{
  if (panoIframe.value && panoIframe.value.contentWindow) {
      panoIframe.value.contentWindow.postMessage({
        action: 'callIframeMethod',
        data: name
      }, '*');
    }
}
const toScene = (name) => {
  sceneName.value = name;
  // let data = { index: 5 };
  // iframe.value.contentWindow.postMessage(data, "*");
  if (panoloaded.value) {
    callIframe(name)
  }else{
    timer = setInterval(() => {
      if (panoloaded.value) {
        callIframe(name)
        clearInterval(timer)
      }
    }, 1000);
  }
  // switchMenu();
};

// 销毁时移除监听器
onUnmounted(() => {
  clearInterval(timer)

});
Window.toScene = toScene;
const iframe = ref(null);

// menu
const menuShow = ref(true);

const showBg = () => {
  showMenu();
};
</script>
<style scoped lang="scss"">

.bgImage{
  width: 100%;
  height: 100%;
  // z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
}

.loading{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  font-size: 30px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.mbcli {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  // transform: translate(-50%, 0);

}

.mbfade-enter-active,
.mbfade-leave-active {
  transition: all 1s;
  transform: translate(-50%, 100%);
 
  // bottom: 100px;
  // opacity: 1;
  // transform: translate(-50%, 0);
}

.mbfade-enter-from,
.mbfade-leave-to {

  opacity: 0;
  transform: translate(-50%, 0%);


  // transform: translate(-50%, 100%);
}

// .mbfade-enter-active,
// .mbfade-leave-active {
//   transition: all 2s;
//   transform: translate(-50%, 0);
// }
// .mbfade-enter-from,
// .mbfade-leave-to {
//   opacity: 0;
//   transform: translate(-50%, 100%);
// }


@media screen and (min-width: 1601px) {
  .menuBox {
    width: 1000px;
    min-width: 1000px;
    position: fixed;
    bottom: 6px;
    height: 54px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 3;
    min-width: 1000px;
  }
  .mbjt {
    width: 1000px;
    height: 36px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 6px;
    bottom: 70px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .sjt {
      width: 22px;
      height: 22px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .menuBox {
    width: 590px;
    min-width: 590px;
    position: fixed;
    bottom: 6px;
    height: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 3;
  }

  .mbjt {
    width: 590px;
    height: 36px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 6px;
    bottom: 40px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .sjt {
      width: 22px;
      height: 22px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 1s;
  transform: translate(-50%, 0);
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translate(-50%, 100%);
}

.logo {
  width: 140px;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 3;
}
.box {
  width: 100%;
  height: 100%;
  // background: url("/bg.png") no-repeat center/contain;
}
.Fullscree {
  position: absolute;
  right: 10px;
  top: 10px;
  // padding: 6px;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background: rgb(189, 118, 95);
  color: white;
  background: url(/static/img/fullscreen.png) no-repeat center/contain;
}

.unfullscreen {
  width: 40px;
  height: 60px;
  background: url(/static/img/windowed.png) no-repeat center/contain !important;
}
</style>